import React from 'react';

import { _ClearTokens } from '@netfront/gelada-react-shared';
import cx from 'classnames';
import gtag from 'ga-gtag';

import { Link } from '../Link/Link';

import styles from './SiteMenu.module.css';

interface SiteMenuProps {
  isAuthenticated?: boolean;
  isDesktop?: boolean;
}

export function SiteMenu({ isAuthenticated, isDesktop }: SiteMenuProps): JSX.Element {
  const handleLogOut = () => {
    _ClearTokens();
    gtag('set', { user_id: '' });
    window.location.href = '/';
  };

  return (
    <nav>
      {isDesktop ? (
        <ul className={cx('l-flex', styles.SiteMenu__ul)}>
          <li className={cx(styles.SiteMenu__li, 'm-r--xxl')}>
            <Link className="h5 h-color--navy" href="/dashboard">
              Dashboard
            </Link>
          </li>
          {isAuthenticated && (
            <li className={cx(styles.SiteMenu__li, 'm-r--xxl')}>
              <Link className="h5 h-color--navy" href="/etools">
                My Tools and Resources
              </Link>
            </li>
          )}

          <li className={cx(styles.SiteMenu__li, isAuthenticated && 'm-r--xxl')}>
            <Link className="h5 h-color--navy" href="/contact">
              Contact
            </Link>
          </li>
        </ul>
      ) : (
        <ul>
          <li className="m-b--large">
            <Link className="h-color--navy-200" href="/dashboard">
              Dashboard
            </Link>
          </li>
          <li className="m-b--large">
            <Link className="h-color--navy-200" href="/etools">
              My Tools and Resources
            </Link>
          </li>
          <li className="m-b--large">
            <Link className="h-color--navy-200" href="/contact">
              Contact
            </Link>
          </li>
          <li className="m-b--large">
            <button className="h-color--navy-200" onClick={handleLogOut}>
              Logout
            </button>
          </li>
        </ul>
      )}
    </nav>
  );
}
