import cx from 'classnames';
import NextLink, { LinkProps as NextLinkProps } from 'next/link';
import React, { ReactNode } from 'react';
import styles from './Link.module.css';

export interface LinkProps extends NextLinkProps {
  children: ReactNode;
  isDisabled?: boolean;
  isExternal?: boolean;
  className?: string;
  onClick?: () => void;
}

export function Link({ children, href, as, isDisabled, isExternal, className, onClick }: LinkProps) {
  if (isDisabled) return <>{children}</>;
  if (isExternal) {
    return (
      <a
        className={cx(styles['Link'], className)}
        // href should always be a string if the route is external
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore
        href={href}
        rel="noopener noreferrer"
        target="_blank"
        onClick={onClick}
      >
        {children}
      </a>
    );
  }
  return (
    <NextLink passHref href={href} as={as}>
      <a className={cx(styles['Link'], className, 'NextLink')} href="#" onClick={onClick}>
        {children}
      </a>
    </NextLink>
  );
}
