import React from 'react';

import cx from 'classnames';

import Link from 'components/Link';

import styles from './Footer.module.css';

export function Footer() {
  return (
    <footer className={cx('no-print', styles.Footer)}>
      <div className={cx(styles['Footer--content'], 'h-container')}>
        <div className={cx(styles['Footer--content__start'])}>
          <span className="md-m-b--none">© {new Date().getFullYear()} | Rethink My Drink</span>
          <Link className={cx(styles['Footer--content__start-link'])} href="/privacy">
            Privacy policy
          </Link>
          <Link className={cx(styles['Footer--content__start-link'])} href="/terms-of-use">
            Terms of use
          </Link>
        </div>

        <div>
          <Link className="flex-1" href="https://netfront.com.au/" isExternal>
            Built by Netfront
          </Link>
        </div>
      </div>
    </footer>
  );
}
