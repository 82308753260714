import { _GetAccessToken } from '@netfront/gelada-react-shared';
import { useEffect, useState } from 'react';

export function useIsAuthenticated() {
  const [isAuth, setIsAuth] = useState(false);

  useEffect(() => {
    setIsAuth(Boolean(_GetAccessToken()));
  }, []);

  return isAuth;
}
