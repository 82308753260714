import React, { useState } from 'react';

import { _GetUserData } from '@netfront/gelada-react-shared';
import cx from 'classnames';
import { useHomeRoute } from 'lib/hooks/useHomeRoute';
import { useRouter } from 'next/router';

import Button from 'components/Button';
import Link from 'components/Link';
import SiteMenu from 'components/SiteMenu';

import { Link as ButtonLink } from '../Link/Link';
import SideDrawer from '../SideDrawer/SideDrawer';

import styles from './Header.module.css';

export interface HeaderProps {
  showMenu?: boolean;
}

const PublicMobileMenu = () => {
  return (
    <>
      <ButtonLink className="m-b h-color--navy-200 " href="/dashboard">
        Dashboard
      </ButtonLink>
      <ButtonLink className="m-b h-color--navy-200 " href="/contact">
        Contact
      </ButtonLink>

      <Button component="link" link={{ href: '/login' }} style="squared">
        LOGIN
      </Button>
    </>
  );
};

export function Header({ showMenu }: HeaderProps): JSX.Element {
  const homeRoute = useHomeRoute();
  const router = useRouter();

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const handleDrawer = () => setIsDrawerOpen(!isDrawerOpen);

  const onDrawerClose = () => setIsDrawerOpen(false);

  const handleProfileRediction = () => {
    router.push('/profile');
  };

  return (
    <header className={cx('no-print', styles.Header)}>
      <div className={styles['Header--Container']}>
        <div className={styles['Header--Logo']}>
          <Link href={homeRoute}>
            <img alt="Rethink my drink logo" className="w-32 md-w-auto" src="/images/logo.svg" />
          </Link>
        </div>

        <div className="l-flex align-center">
          <div className="desktop-only-lg">
            <SiteMenu isAuthenticated={showMenu} isDesktop />
          </div>

          <div className="hide-lg">
            <SideDrawer isOpen={isDrawerOpen} onClose={onDrawerClose}>
              {showMenu ? <SiteMenu /> : <PublicMobileMenu />}
            </SideDrawer>

            <button className="l-flex" onClick={handleDrawer}>
              <img alt="menu icon" src="/images/icon-menu.svg" />
            </button>
          </div>
        </div>
        <div className="desktop-only-lg">
          {showMenu ? (
            <div
              className="desktop-only-lg l-flex--important cursor-pointer justify-center align-center h-background--navy circle--small h-color--white weight-700"
              onClick={handleProfileRediction}
            >
              {_GetUserData()?.firstname?.slice(0, 1)}
            </div>
          ) : (
            <>
              <Button component="link" link={{ href: '/login' }} style="squared">
                LOGIN
              </Button>
            </>
          )}
        </div>
      </div>
    </header>
  );
}
